.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #20252b;
  /* overflow: auto; */
  overflow: hidden;
  position: fixed;
}

.navbar {
  padding: 0.1em 0.5em 0em 1em;
}

nav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.212);
  border-radius: .3em;
}

nav img {
  width: 1.5em;
  margin: 0em 0.3em 0.2em 0em;
}

@media (max-width: 575px) {
  .navbar {
    padding: 0em 0em 0em 0em;
  }
  .navbar-brand {
    padding: 0em 0em 0em 0.5em;
  }
}

.logo {
  text-decoration: none;
  color: azure;
  /* padding-left: 0em; */
}

.home-container {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  height: 100%;
}

@media (max-width: 575px) {
  .home-container {
    flex-direction: column;
    overflow: auto;
  };
}


.close {
  position: relative;
  right: 0.3em;
  top: 0.5em;
  width: 32px;
  height: 32px;
  opacity: 0.7 !important;
  cursor: pointer;
}

.close:hover {
  opacity: 1 !important;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 23px;
  width: 2.5px;
  background-color: rgb(255, 255, 255);
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.about {
  background-color: rgba(245, 245, 245, 0.082);
  color: whitesmoke;
  height: 100%;
  padding: 4em;
  overflow-y: auto;
}

.about h1 {
  padding-bottom: 1em;
}

.about h2 {
  padding-bottom: 0.5em;
  padding-top: 1em;
}

.about h4 {
  padding-bottom: 1em;
  padding-top: 1em;
}


.disclaimer {
  color: rgba(255, 255, 255, 0.507);
  font-size: small;
  margin-top: 5em;
}

.bmc-logo {
  width: 10em;
  height: auto;
  margin-top: 2em;
}

footer {
  position:relative;
  z-index: 5;
  text-align: center;
  margin: 0;
  width: 100%;
  bottom: 0;
  font-size: 0.8em;
  padding:  0em .5em;
  background-color: rgba(43, 43, 43, 0.808);
  color: rgba(204, 204, 204, 0.699);
}

footer p {
  margin: 0;
}


.centered-image {
  padding-top: 2em;
  padding-bottom: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 80%; */
}

.centered-image img {
  max-width: 70%;
  max-height: 80%;
  height: auto;
  width: auto; /* Ensures the image does not stretch if the aspect ratio changes */
}