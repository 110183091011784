.map {
  display:flex;
  position:relative;
  width: 100%;
  min-height: 20em;
  height: 100%;
  /* filter: blur(10px); */
  /* -webkit-filter: blur(10px); */
  transition: 150ms;
}

.loading {
  filter: blur(13px);
  -webkit-filter: blur(13px);
  transition: 150ms;
}

.loading-spinner {
  /* filter: blur(1px); */
  /* -webkit-filter: blur(1px); */
  position: absolute;
  z-index: 5;
  /* width: 100%; */
  /* height: 100%; */
  /* text-align: center; */
  margin: 0;
  left: 50% ;
  top: 50% ;
  transform: translate(-50%, -50%) ;
}

.loading-spinner-bcgr {
  filter: blur(1px);
  -webkit-filter: blur(1px);
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.map .map-airport-label {
  color: white;
  position: fixed;
  text-decoration: none;
  background-color: #006097de;
  border: 1px solid white;
  padding: 0em .2em;
  border-radius: .5em;
  letter-spacing: .1em;
  font-family: Monospace;
}

.map .red {
  background-color: #a80707b4;
}

.map .green {
  background-color: #01a156dc;
}

.map-tooltip-header {
  text-align: center;
  font-size: 1.2em;
  /* font-family: Monospace; */
  /* font-weight: bold; */
  border-radius: .1rem .1rem 0 0;
  padding: 0.2em;
  margin: 0;
  background-color: rgb(21, 77, 72);
  color: white;
}

.mapboxgl-popup-content {
  font-size: 0.9em;
  /* border: 1px solid rgba(255, 255, 255, 0.356); */
  /* border: none; */
  padding: 0;
  margin: 0;
  line-height: normal;
  font-family: Monospace;
}

.map-tooltip-content {
  font-size: 1.1em;
  padding: .2em .5em;
}

.map-tooltip-content .rqr{
  display: inline;
}