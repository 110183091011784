.header-ap {
  background-color: #318b78;
}

.header-ap h1 {
  font-family: Monospace;
  user-select: none;
  cursor: pointer;
}

.header-ap h2 {
  font-family: Monospace;
}

.card-country-coordinates {
  background-color: #ffffff;
  font-size: 0.95em;
  padding: 0.3rem 1.25rem;
}

.card-country {
  display: flex;
  text-indent: 0.3em;
  align-items: center;
}

.card-coordinates {
  display: flex;
  text-indent: 0.3em;
  font-size: 0.75em;
}

.card-amsl-oat {
  border-top: 1px solid rgba(86, 107, 106, 0.514);
  background-color: white;
  color: #555;
  font-size: 1em;
  font-weight: 600;
  padding: 0.1rem 1.25rem;
}

.coordinates {
  font-size: 0.75em;
}

.country-flag {
  width: auto;
  height: 1em;
}

.runway-data-divider {
  padding: 0.3rem 1.25rem;
  text-align: center;
  color: white;
  background-color: #3a504cb9;
  font-size: 1em;
  margin: 0em;
  user-select: none;
  cursor: pointer;
}

.runway-data-divider.expanded::after {
  content: "\2212";
  float: right;
  transition: 0.2s ease-out;

}

.runway-data-divider.collapsed::after {
  content: '\002B';
  float: right;
  transition: 0.2s ease-out;
}

.card-runway-list {
  font-size: 0.9em;
  padding: 0.5em 0 0.5em 0;
}

.card-runway-list table {
  width: 100%;
  text-indent: 2em;
}

.card-runway-list th {
  text-align: left;
  border-bottom: 1px solid rgba(86, 107, 104, 0.514);
}

.card-runway-list td {
  padding-top: 0.2em;
}

.runway-list-item {
  width: 100%;
  padding-top: 1.25em;
  width: 100%;
  /* text-align: center; */
  /* color: red; */
}

.runway-list-item-hovered td {
  background-color: #56726ca9;
  color: white;
}

.runway-name {
  font-weight: bold;
  color: white;
  background-color: #c10038;
  padding: 0em 0.2rem 0em 0.2em;
  border: 1px solid rgb(49, 49, 49);
  border-radius: 0.3em;
}

.ac-list {
  /* background-color: #00c3ff; */
  overflow: auto;
  list-style-type: none;
  margin: 1em;
  flex-wrap: wrap;
  /* align-self: center; */
  max-height: 20em;
  transition: 0.15s ease-out;
}

.ac-list .ac {
  width: 19em;
  border: 1px solid rgb(49, 49, 49);
  border-radius: 0.3em;
  text-align: left;
  text-indent: 1em;
  user-select: none;
  cursor: pointer;
  font-size: 0.95em;
  /* text-decoration: none; */
  
}

.ac-list.collapsed {
  max-height: 0;
  transition: .15s ease-out;
  padding: 0;
  overflow: hidden;
}


.ac-list .ac-selected {
  font-weight: 600;
  box-shadow: inset -1em 0 2px rgb(41,65,122);
  }

.ac-list li:hover {
  background-color: #53535369;
}

.ac-list li {
  margin: 3px;
  left: 3px;
  /* text-decoration: none; */
}

.ac-list a {
  text-decoration: none;
}

.ac-list .red {
  background-color: rgba(255, 0, 0, 0.342);
}

.ac-list .green {
  background-color: rgba(0, 128, 0, 0.281);
}

.ac-list .yelow {
  background-color: rgba(255, 255, 0, 0.226);
}



.ap-list {
  /* background-color: #00c3ff; */
  overflow: auto;
  list-style-type: none;
  padding: 1em;
  align-self: center;
  /* text-decoration: none; */
}

.ap-list .ap {
  width: 17em;
  border: 2px solid rgb(49, 49, 49);
  border-radius: 0.3em;
  text-align: left;
  text-indent: 1em;
  /* text-decoration: none; */
}

.ap-list li:hover {
  background-color: #53535369;
}

.ap-list li {
  margin: 3px;
  left: 3px;
  /* text-decoration: none; */
}

.ap-list a {
  text-decoration: none;
}

.ap-list .red {
  background-color: rgba(255, 0, 0, 0.342);
}

.ap-list .green {
  background-color: rgba(0, 128, 0, 0.281);
}

.ap-list .yelow {
  background-color: rgba(255, 255, 0, 0.226);
}


