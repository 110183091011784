.sidebar-r {
    display:flex;
    position:absolute;
    z-index: 3;
    flex-direction: column;
    min-width: 21em;
    right: -21em;
    top: 0.7em;    
    height: auto;
    /* border: 3px solid red; */
    /* background-color: #e3e7ec; */
    transition: 150ms;
    opacity: 0;
    overflow-y: auto;
  }

  .sidebar-r::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border-radius: .5em;
    }
    
  .sidebar-r::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px  #ecedee;
    border-radius: .3em;
  }
  
  .sidebar-r::-webkit-scrollbar-thumb {
    background: #90949cef;
    border-radius: .3em;
  }
  
  @media (max-width: 575px) {
    .sidebar-r {
      z-index: -1;
      width: 100%;   
      transition: 150ms;  
      overflow-y: unset; 
    };
  }

.sidebar-r.active {
    right: 0.5em;
    opacity: 1;
    transition: 150ms;
    max-height: 100vh;
  }

  @media (max-width: 575px) {
    .sidebar-r.active {
      position: relative;
      z-index: 3;
      height: auto;
      right: 0em;
      top: 0em;      
    }
  }



.ac-card {
    /* display:flex; */
    /* position: relative; */
    /* z-index: 3; */
    /* flex-direction: row; */
    /* z-index: 8; */
    transition: 100ms;
    opacity: 0;
    color: white;
    padding: 0em 0em 0.2em 1em;
    margin: 3px;
    background-color: #29417a;
    border: 1px solid rgba(255, 255, 255, 0.226);
    border-radius: .9em;
}

@media (max-width: 575px) {
  .ac-card {
    margin: 0;
    border-radius: 0;
  }
}

.ac-card.red {
  border: 3px solid rgba(204, 23, 23, 0.596);
}

.ac-card.green {
  border: 3px solid rgba(35, 182, 54, 0.692);
}


.ac-card.active {
  opacity: 1;
  transition: 100ms;
}

.ac-cards {
  /* display:flex; */
}

.card-same-row {
    display: flex;
    align-items: center;
  }

  
.card-header-a {
    color: white;
    padding: .5em 0em 0em 1em;
    }
  
.card-header-a h1 {
    font-size: 1.1em;
    font-weight: bold;
  }
  
.card-header-a h2 {
    font-size: 0.95em;
  }
  
  
.links {
    border-top: 1px solid rgba(86, 107, 104, 0.514);
    background-color: #ffffff;
    font-size: .95em;
    padding: .2rem 1.25rem
  }
    
.extlnk {
    height: 1em;
    width: 1em;
  }

.locate {
    position: relative;
    height: 1em;
    width: 1em;
    left: .5em;
    top: .25em;
    cursor: pointer;
  }

  
.info {
  position: relative;
  bottom: 0.2em;
  left: 0.2em;
  width: 1em;
  height: 1em;
  padding: 1em, 1em, 1em, 1em;
  color: red;
}