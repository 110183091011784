.sidebar {
    display:flex;
    position:absolute;
    z-index: 3;
    flex-direction: column;
    min-width: 23em;
    left: -23em;
    height: 100%;
    /* border: 3px solid red; */
    background-color: #e3e7ec;
    transition: 150ms;
    opacity: 0;
  }
  
  @media (max-width: 575px) {
    .sidebar {
      z-index: -1;
      width: 100%;
      transition: 150ms;
    };
  }

.sidebar.active {
    left: 0em;
    opacity: 1;
  }

  @media (max-width: 575px) {
    .sidebar.active {
      position: relative;
      z-index: 3;
      height: auto;
    }
  }
  
.card-header-a {
    color: white;
    padding: .5em 0em 0em 1em;
    }
  
.card-header-a h1 {
    font-size: 1.1em;
    font-weight: bold;
    display: flex;
  }
  
.card-header-a h2 {
    font-size: 0.95em;
  }
  
.card-close{
    position: absolute;
    right: 0;
    top: 0;
    color: white;
  }
   
.same-row {
    /* display: flex; */
    /* align-items: center; */
    /* min-width: 10em; */
    display: grid;
    grid-template-columns: 2.5em 13em 4.4em 6.2em;
    align-items: center;
    /* gap: 8px; */
  }
  
.links {
    border-top: 1px solid rgba(86, 107, 104, 0.514);
    background-color: #ffffff;
    font-size: .95em;
    padding: .2rem 1.25rem
  }
    
.extlnk {
    height: 1em;
    width: 1em;
  }

.locate {
    position: relative;
    height: 1em;
    width: 1em;
    left: .5em;
    top: .25em;
  }

  
.info {
  position: relative;
  bottom: 0.2em;
  left: 0.2em;
  width: 1em;
  height: 1em;
  padding: 1em, 1em, 1em, 1em;
  color: red;
}