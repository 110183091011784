.search {
    z-index: 4;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, 0);
    min-width: 23em;
    align-items: top;
    border-radius: 0.5em;
  }

  @media (max-width: 575px) {
    .search {
      position: relative;
      width: 100%;
      top: 0;
      margin: auto;
    };
    .search-ap {
      position: absolute;
    }
  }

.search-ap {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #23292ef5;
  list-style-type:none;
  padding-left: 0em;
  top: 2.5em;
}

.search-ap li:hover {
  background-color: #5e6369f6;
}

.search li {
  padding: .23em;
  padding-left: .5em;
  border-bottom: solid 1px #ffffff23;
}

.search-ap .ap {
  color: #36ebc4;
  text-decoration: none;
}

.search-ap .head {
  font-family: Monospace;
}

.search-ap .ap .icao {
  display: inline;
  padding-right: 0.5em;
  border-right: solid 2px #00ffc854;
}

.search-ap .ap .name {
  display: inline;
  padding-left: 0.5em;
}

.search-ap .ap .other {
  display: block;
  font-size: 0.9em;
}

.search-ap .ap .city {
  display: inline;
  padding-right: 0.3em;
  margin-right: 0.3em;
}
.search-ap .ap .country {
  display: inline;
  padding-right: 0.3em;
}

.search-ap .ac {
  color: #9fd4ff;
  text-decoration: none;
}

.search-ap .ac .other {
  display: block;
  font-size: 0.9em;
}

.search-ap.open {
  display: block;
  overflow: auto;
  max-height: 30em;
  border-bottom: solid 1px  rgba(255, 255, 255, 0.212);;
  border-left: solid 1px  rgba(255, 255, 255, 0.212);;
  border-right: solid 1px  rgba(255, 255, 255, 0.212);;
  border-radius: 0.5em;
}

.search-ap::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: .5em;
  }
  
.search-ap::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px  #9fa8b1;
  border-radius: .3em;
}

.search-ap::-webkit-scrollbar-thumb {
  background: #9fa8b1;
  border-radius: .3em;
}