.ac-card-holder {
    display:flex;
    position:absolute;
    /* z-index: 3; */
    flex-direction: row;
    z-index: 8;
    /* background-color: rgb(30, 71, 255);
    border: 1px solid rgb(49, 49, 49);
    border-radius: .3em; */
    transition: 150ms;
    transition: transform 0.3s ease;
}

.ac-card {
    /* display:flex; */
    /* position: relative; */
    /* z-index: 3; */
    /* flex-direction: row; */
    /* z-index: 8; */
    font-family: Monospace;
    background-color: rgba(24, 47, 94, 0.938);
    border: 1px solid rgb(49, 49, 49);
    border-radius: .3em;
    transition: 150ms;
    transition: transform 0.3s ease;
}

.ac-card .ac-model-button {
    margin-top: 0.5em;
    padding: 0em 0.5em 0em 0.5em;
    border: 1px solid rgba(218, 218, 218, 0.295);    
    border-radius: .3em;
    display: inline-block;
    font-weight: bold;
    background-color: rgba(24, 37, 66, 0);
    user-select: none;
    cursor: pointer;
    text-decoration: none; /* Remove underline, if needed */
    color: inherit; /* Inherit text color */
}


.ac-card .ac-model-button:hover {
    background-color: rgba(36, 57, 99, 0.938);
    border: 1px solid rgba(255, 255, 255, 0.671);  
  }

.ac-card .engine {
    font-weight: normal;
    font-size: smaller;
}

.calculator-card {
    font-size: 0.95em;
    margin-left: 0.5em;
    padding-top: 0.5em;
    font-family: Monospace;
}

.calculator-card div {
    font-size: 0.95em;
    /* margin-left: 0.5em; */
    /* padding-left: 0.5em;
    padding-top: 0.5em; */
}

.ac-range-card{
    margin-left: 4px;
    width: 14em;
  }

.tow-slider {
    display: flex;
  }

.card-same-row.disabled {
    opacity: 0.6; /* Reduce opacity */
    pointer-events: none; /* Disable pointer events */
}

.toggle-symbol {
    display: inline-block;
    /* margin-right: 10px; */
    font-size: 1.5em;
    cursor: pointer;
    user-select: none;
    background: none;
    border: none;
    color: aliceblue;
  }

.tow-slider.expanded .toggle-symbol::before {
content: "\2212"; /* Minus sign for collapse */
}

.tow-slider.collapsed .toggle-symbol::before {
content: "\002B"; /* Plus sign for expand */
}

/* .advanced-sliders {
    max-height: 3em;
    transition: .15s ease-out;
    padding-left: .3em;
} */



/* .advanced-sliders.hidden {
    max-height: 0;
    transition: .15s ease-out;
    padding: 0;
    overflow: hidden;
} */

.rwl {
    display: flex;
    /* align-items: center; */
    margin-bottom: 0.3em;
    /* font-weight: bold; */
    font-size: 0.95em;
    color: rgb(233, 233, 233);
    white-space: nowrap;
  }

.rwl .value {
    font-weight: bold;
    font-size: 1em;
    padding-left: .5em;
}

.units {
    padding-left: .5em;
}






.close {
    position: relative;
    /* right: 0.3em; */
    /* top: 0.5em; */
    color: black;
    width: 32px;
    height: 32px;
    opacity: 0.5;
    /* z-index: 8; */
  }