.header-ac {
  background-color: #29417a;
  font-family: Monospace;
  display: inline-block;
  /* padding-right: 0.1em; */
}

.header-ac div{
  padding-right: 0.5em;
  margin-bottom: 0.3em;
}
  
.card-engine {
  background-color: #ffffff;
  font-size: .90em;
  padding: .4em 1em
}

.card-engine h2{
  font-size: 1.2em;
  font-family: monospace;
  margin: auto;
}
  
.card-thrust {
  border-top: 1px solid  rgba(86, 107, 106, 0.514);
  background-color: white;
  color: #555;
  font-size: 1em;
  font-weight: bold;
  font-family: monospace;
  padding: .1rem 1rem
}  

.performance-divider {
  font-size: 1.2em;
  text-align: center;
  margin: auto;
  color: white;
  background-color: #29417aad;
  font-family: monospace;
  font-weight: bold;
  padding: .2rem 1rem;
}

.card-mass {
  font-size: .85em;   
  width: 100%;
  text-align: center;
  background-color: white;
  color: rgb(78, 78, 78);
}

.card-mass th{
  padding: .1em 1rem;
  border-bottom: 1px solid rgb(201, 201, 201);
}

.card-mass td{
  padding: .2em 1.25em;
}

.slider-group {
  display: inline;
}

.calculator-divider {
  font-size: 1em;
  font-weight: 400;
  font-family: monospace;
  margin: auto;
  text-align: center;
  color: white;
  padding: .2rem 1rem;
  background-color: #29417a85;
}

.calculator {
  /* margin-top: 0.5em; */
  /* text-align: center; */
  font-size: 0.9em;
  /* margin-left: 0.5em; */
  padding-top: 0.5em;
  font-family: Monospace;
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
}

.calculator div {
  /* font-size: 0.95em; */
  /* margin-left: 0.5em; */
  padding-left: 0.2em;
}

.calculator .units {
  /* margin-bottom: 0.5em; */
}

@media (max-width: 575px) {
  .calculator div {
    /* justify-content: center */
  }
}

.results {
  /* margin-top: 0.5em; */
  /* text-align: center; */
  font-size: 0.9em;
  /* margin-left: 0.5em; */
  padding-top: 0.5em;
  font-family: Monospace;
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
}

.ac-range {
  /* width: 13em; */
  width: 100%;
}

.ac-input {
  margin-left: 0.5em;
  /* margin-top: 0.2em; */
  width: 4em;
  height: 1.2em;
  /* font-size: 0.9em; */
  /* width: 100%; */
  text-align: right;
}

.result {
  text-align: right;
}

.units {
  text-align: left;
}

.runway-length {
  /* display: flex; */
  margin-left: 0.4em;
  margin-bottom: 0.2em;
  font-weight: bold;
  font-size: 1.1em;
  color: rgb(53, 53, 53);
  display: grid;
  grid-template-columns: 14.3em 3.3em 7.5em;
  align-items: center;

}

.tooltip {
  font-size: 0.5em;
}